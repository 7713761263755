<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->


<template>
  <div>
    <CRow>
      <CCol md sm='12' class='mb-sm-3 mb-0'>
        <CCard>
          <CCardBody>
            <div class='text-value-lg'>{{ getBCValue }}</div>
            <div>{{ getPlural('bc') }}</div>
            <CProgress
              class='progress-xs my-2'
              :precision='1'
              color='success'
              :value='getBCRatio'
            />
            <div class='text-muted'>{{ $t('dashboard.activeBlockchainNetworks') }}</div>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol md sm='12' class='mb-sm-3 mb-0'>
        <CCard>
          <CCardBody>
            <div class='text-value-lg'>{{ getChannelValue }}</div>
            <div>{{ getPlural('channel') }}</div>
            <CProgress
              class='progress-xs my-2'
              :precision='1'
              color='primary'
              :value='getChannelRatio'
            />
            <div class='text-muted'>{{ $t('dashboard.activeChannels') }}</div>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol md sm='12' class='mb-sm-3 mb-0'>
        <CCard>
          <CCardBody>
            <div class='text-value-lg'>{{ getOrganisationValue }}</div>
            <div>{{ getPlural('org') }}</div>
            <CProgress
              class='progress-xs my-2'
              :precision='1'
              color='warning'
              :value='getOrganisationRatio'
            />
            <div class='text-muted'>{{ $t('dashboard.activeOrganizations') }}</div>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol md sm='12' class='mb-sm-3 mb-0'>
        <CCard>
          <CCardBody>
            <div class='text-value-lg'>{{ getIncValue }}</div>
            <div>{{ getPlural('inc') }}</div>
            <CProgress
              class='progress-xs my-2'
              :precision='1'
              :color='getColor(getIncRatio)'
              :value='getIncRatio'
            />
            <div class='text-muted'>{{ $t('dashboard.activeIncidents') }}</div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CCard>
          <CDataTable
            striped
            :addTableClasses='isShowTable ? "tableWithBody" : "tableWithoutBody"'
            :items='getEndpointStats'
            :fields='fieldsForTable'
            :items-per-page='100'
            :noItemsView='{ noResults: "Нет данных", noItems: "Нет данных" }'
            responsive
            clickable-rows
            @row-clicked="itemClicked"
          >
            <template #icon='{item}'>
              <td class='width-sm-1' align='center'>
                <fa-icon icon='database' size='lg' :style='getColorIcon(item.is_available)'/>
              </td>
            </template>

            <template #hostname='{item}'>
              <td class='width-sm-2'>
                <div>{{ item.hostname }}</div>
              </td>
            </template>

            <template #disk_capacity='{item}'>
              <td class='width-sm-2'>
                <div class='text-value-lg' v-if='item.disk_capacity > 1'>{{ item.disk_capacity }} %</div>
                <div class='text-value-lg' v-if='item.disk_capacity <= 1  && item.is_available'>&lt;1%</div>
                <div class='text-value-md' v-if='item.disk_capacity === 0 && !item.is_available' style="color: grey">{{ $t('dashboard.unknown') }}</div>
                <CProgress
                  v-if='item.disk_capacity > 0'
                  class='progress-xs my-2'
                  :precision='1'
                  :color='getColor(item.disk_capacity)'
                  :value='item.disk_capacity'
                />
              </td>
            </template>

            <template #ram_capacity='{item}'>
              <td class='width-sm-2'>
                <div class='text-value-lg' v-if='item.ram_capacity > 1'>{{ item.ram_capacity }} %</div>
                <div class='text-value-lg' v-if='item.ram_capacity <= 1 && item.is_available'>&lt;1%</div>
                <div class='text-value-md' v-if='item.ram_capacity === 0 && !item.is_available' style="color: grey">{{ $t('dashboard.unknown') }}</div>
                <CProgress
                  v-if='item.ram_capacity > 0'
                  class='progress-xs my-2'
                  :precision='1'
                  :color='getColor(item.ram_capacity)'
                  :value='item.ram_capacity'
                />
              </td>
            </template>

            <template #cpu_capacity='{item}'>
              <td class='width-sm-2'>
                <div class='text-value-lg' v-if='item.cpu_capacity > 1'>{{ item.cpu_capacity }} %</div>
                <div class='text-value-lg' v-if='item.cpu_capacity <= 1 && item.is_available'>&lt;1%</div>
                <div class='text-value-md' v-if='item.cpu_capacity === 0 && !item.is_available' style="color: grey">{{ $t('dashboard.unknown') }}</div>
                <CProgress
                  v-if='item.cpu_capacity > 0'
                  class='progress-xs my-2'
                  :precision='1'
                  :color='getColor(item.cpu_capacity)'
                  :value='item.cpu_capacity'
                />
              </td>
            </template>

            <template #incidents='{item}'>
              <td class='width-sm-1'>
                <a href='#' class='text-value-lg' :style='getColorIncidents(item.incidents)'>{{ item.incidents }}</a>
              </td>
            </template>
          </CDataTable>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Dashboard',
  data() {
    return {
      fieldsForTable: [
        { key: 'icon', label: ''},
        { key: 'hostname', label: this.$i18n.t('dashboard.node') },
        { key: 'disk_capacity', label: this.$i18n.t('dashboard.disk')  },
        { key: 'ram_capacity', label: this.$i18n.t('dashboard.memory') },
        { key: 'cpu_capacity', label: this.$i18n.t('dashboard.cpu') },
        { key: 'incidents', label: this.$i18n.t('dashboard.incidentsTitle') }
      ]
    };
  },
  async mounted() {
    await this.getStaticInfo();
  },
  computed: {
    ...mapGetters(['getStatistics', 'getBlockChainNets', 'getStatChannels', 'getStatOrg', 'getStatInc']),
    getBCValue() {
      return this.getBlockChainNets?.value;
    },
    getBCRatio() {
      return this.getBlockChainNets?.ratio;
    },
    getChannelValue() {
      return this.getStatChannels?.value;
    },
    getChannelRatio() {
      return this.getStatChannels?.ratio;
    },
    getOrganisationValue() {
      return this.getStatOrg?.value;
    },
    getOrganisationRatio() {
      return this.getStatOrg?.ratio;
    },
    getIncRatio() {
      return this.getStatInc?.ratio;
    },
    getIncValue() {
      return this.getStatInc?.value;
    },
    getEndpointStats(){
      return this.getStatistics.endpoint_stats
    },
    isShowTable(){
      return this.getStatistics.endpoint_stats.length > 0
    }
  },
  methods: {
    async getStaticInfo() {
      await this.$store.dispatch('getStatistics');
    },
    getColor(value) {
      if (value <= 50) return 'success';
      if (value > 50 && value <= 80) return 'warning';
      if (value > 80) return 'danger';
      return 'success';
    },
    getColorIcon(isSuccess){
      return  { color: isSuccess ? "green" : "gray" }
    },
    getColorIncidents(count){
      if (count === 0) return {color: 'transparent' }
      if (count === 1 || count === 2) return {color: 'orange' }
      if (count > 2) return {color: 'red' }
    },
    getPlural(value){
      const values = {
        bc: this.getBlockChainNets?.value,
        channel: this.getStatChannels?.value,
        org: this.getStatOrg?.value,
        inc: this.getStatInc?.value
      }
      const types = {
        bc: [this.$i18n.t('pluralValues.network1'), this.$i18n.t('pluralValues.network2'), this.$i18n.t('pluralValues.network2')],
        channel: [this.$i18n.t('pluralValues.channel1'), this.$i18n.t('pluralValues.channel2'), this.$i18n.t('pluralValues.channel2')],
        org: [this.$i18n.t('pluralValues.org1'), this.$i18n.t('pluralValues.org2'), this.$i18n.t('pluralValues.org2')],
        inc: [this.$i18n.t('pluralValues.incidents1'), this.$i18n.t('pluralValues.incidents2'), this.$i18n.t('pluralValues.incidents2')]
      }
      const cases = [2, 0, 1, 1, 1, 2];
      const count = values[value];
      const variant = (count % 100 > 4 && count % 100 < 20) ? 2 : cases[ Math.min(count % 10, 5)]
      return types[value][variant]
    },
    itemClicked(item){
      this.$router.push({path: `/endpoints/${item.id}`})
    }
  }
};
</script>
<style>
.width-sm-1 {
  width: 30px;
}
.width-sm-2 {
  width: 20%;
}
.tableWithoutBody tbody h2 {
  font-size: 14px !important;
  font-weight: bold;
  color: #a7a7a7;
}
.tableWithoutBody tbody svg {
  display: none;
}
.tableWithoutBody tbody .text-center.my-5 {
  padding: 0;
  margin: 0 !important;
}
</style>
